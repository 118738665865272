import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../store/courses"
import LayoutCourse from "../../../../layouts/course"

import AudioPlayer from "../../../../components/audio-player"
import Box from "../../../../components/box"
import Paragraph from "../../../../components/paragraph"
import CourseFooterNext from "../../../../components/course-footer-next"
import FloatContainer from "../../../../components/float-container"
import Heading from "../../../../components/heading"
import Image from "../../../../components/image"
import NoBreak from "../../../../components/no-break"
import Seo from "../../../../components/seo"
import Stack from "../../../../components/stack"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  const data = useStaticQuery(graphql`
    query JahreszeitenKapitel1Query {
      portrait: file(relativePath: { eq: "kurse/schreiner-lohbauer.png" }) {
        ...mediumImage
      }
      turmzimmer: file(relativePath: { eq: "kurse/turmzimmer-aussicht.jpg" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          title="Bist du bereit?"
          label="Los Geht's"
          to="/kurse/jahreszeiten/01-jahreszeitengedicht/fruehling"
        />
      }
      navigation={navigation}
    >
      <Seo title="Hölderlin und die Jahreszeiten" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whisper">
              Kapitel 1
            </Heading>
            <Heading as="h2" level={2}>
              Hölderlin und die Jahreszeiten
            </Heading>
          </Stack>
          <AudioPlayer src={`kurse/jahreszeiten/einleitung`} />
          <Box>
            <FloatContainer align="right" width="40%">
              <Image
                image={data.portrait.childImageSharp.gatsbyImageData}
                alt="Handzeichnung zeigt ein Portrait Hölderlins gemalt von Lohbauer"
                caption="So sah Hölderlin aus, als er schon etwas älter war und im Turm gelebt hat."
                attribution="DLA Marbach"
              />
            </FloatContainer>
            <Paragraph dropcap={true}>
              Immer wieder hat Friedrich Hölderlin in seinen Gedichten die
              Jahreszeiten beschrieben. Vor allem in seinen letzten 36
              Lebensjahren, die er in Tübingen verbracht hat. Dort hatte er ein
              Zimmer in einem Turm, der heute Hölderlinturm heißt. Gleich
              mehrere Gedichte mit den Titeln ›Der Frühling‹, ›Der Sommer‹, ›Der
              Herbst‹ und ›Der Winter‹ sind hier entstanden. Auf die
              Jahreszeiten ist Verlass – das hat Hölderlin wohl gefallen. Jahr
              für Jahr kehren sie in derselben Reihenfolge wieder, gliedern den
              Jahresverlauf und folgen einer festen Ordnung. Auf den Frühling
              folgt der Sommer, dann der Herbst und darauf der Winter. Und jede
              Jahreszeit färbt die Welt in ein anderes Licht, verändert die
              Bäume, Straßen und Flüsse.
            </Paragraph>
          </Box>
          <Box>
            <FloatContainer width="40%">
              <Image
                image={data.turmzimmer.childImageSharp.gatsbyImageData}
                alt="Die heutige Aussicht aus Hölderlins Turmzimmer"
                caption="Die heutige Aussicht aus Hölderlins Turmzimmer."
                attribution="David Franck"
              />
            </FloatContainer>
            <Paragraph>
              Diese Veränderungen konnte Hölderlin aus seinem Zimmer im Tübinger
              Turm genau mitverfolgen. Es hatte 5 Fenster. Aus ihnen konnte
              Hölderlin auf den Neckar und die dahinter liegenden Berge und
              Wälder sehen. Eine tolle Aussicht! So wurden die vier Jahreszeiten
              zu einem Thema, das Hölderlin immer wieder von Neuem in seinen
              Gedichten festgehalten hat. Der Frühling war wohl seine
              Lieblings-Jahreszeit. Ganze 9 Gedichte hat er dazu verfasst.
            </Paragraph>
          </Box>
          <Paragraph>
            Eines davon werden wir uns nun ganz genau ansehen. Du wirst staunen,
            was <NoBreak>Hölderlin</NoBreak> darin alles versteckt hat!
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
